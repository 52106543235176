// methods
import { getSchoolShow } from '@/api/basic';
// components
import { Button } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import schoolDialog from './components/schoolDialog';

export default {
  name: 'School',
  props: {},
  data() {
    return {
      schoolData: {},
      visible: false,
      formData: {
        schoolName: '',
        schoolType: '',
        schoolTypeName: '',
        schoolShortName: '',
      },
    };
  },
  components: {
    [Button.name]: Button,
    basicHeader,
    qlPagination,
    schoolDialog,
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getSchoolShow();
    },
    getSchoolShow() {
      return new Promise((resolve, reject) => {
        getSchoolShow().then(
          (res) => {
            this.schoolData = res.result.school || {};
            this.schoolData['schoolKey'] = res.result.schoolKey || '';
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
      });
    },

    handleAdd() {
      this.formData = {
        schoolKey: this.schoolData.schoolKey,
        schoolId: this.schoolData.schoolId,
        schoolName: this.schoolData.schoolName,
        schoolType: this.schoolData.schoolType,
        schoolTypeName: this.schoolData.schoolTypeObj ? this.schoolData.schoolTypeObj.typeName : '',
        schoolShortName: this.schoolData.schoolShortName,
      };
      this.visible = true;
    },

    handleSuccess() {
      this.getSchoolShow().then(() => {
        this.visible = false;
      });
    },
  },
};
