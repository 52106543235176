<template>
  <el-dialog custom-class="ql-dialog" title="编辑学校" :visible.sync="visible" width="568px">
    <el-form ref="form" :model="formData" :rules="rules" label-width="72px">
      <el-form-item label="学校编号" prop="schoolKey" size="small">
        <el-input v-model="formData.schoolKey" disabled></el-input>
      </el-form-item>
      <el-form-item label="学校名称" prop="schoolName" size="small">
        <el-input v-model="formData.schoolName"></el-input>
      </el-form-item>
      <el-form-item label="学校类型" size="small">
        <el-input :value="formData.schoolTypeName" disabled></el-input>
      </el-form-item>
      <el-form-item label="学校代码" prop="schoolShortName" size="small">
        <el-input v-model="formData.schoolShortName"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取 消</el-button>
      <el-button type="primary" @click="handleSave" size="small">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input } from 'element-ui';
import { checkHtml } from '@/utils/validate';
import { saveSchool } from '@/api/basic';

export default {
  name: 'SchoolDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
  },
  data() {
    const validateSchoolName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('学校名称不能为空'));
        return;
      } else if (checkHtml(value)) {
        callback(new Error('学校名称不能包含非法字符'));
        return;
      }
      callback();
    };
    const validateSchoolShortName = (rule, value, callback) => {
      if (value && value.length > 20) {
        callback(new Error('学校代码不能超过20个字'));
        return;
      }
      callback();
    };
    return {
      rules: {
        schoolName: [
          {
            validator: validateSchoolName,
            trigger: 'blur',
          },
        ],
        schoolShortName: [
          {
            validator: validateSchoolShortName,
            trigger: 'blur',
          },
        ],
      },
      formData: {
        schoolKey: '',
        schoolId: '',
        schoolName: '',
        schoolType: '',
        schoolTypeName: '',
        schoolShortName: '',
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
  },
  methods: {
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveSchool({
            schoolId: this.formData.schoolId,
            schoolName: this.formData.schoolName,
            schoolType: this.formData.schoolType,
            schoolShortName: this.formData.schoolShortName,
          }).then((res) => {
            if (res && res.result === 0) {
              this.$emit('success', this.formData);
            }
          });
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.schoolDialog {
}
</style>
